import React from 'react';
import invariant from 'tiny-invariant';

export interface LayoutProviderContext {
  defaultLayout: React.ElementType;
}

const Context = React.createContext<LayoutProviderContext | null>(null);

if (process.env.NODE_ENV !== 'production') {
  Context.displayName = 'LayoutProviderContext';
}

export interface LayoutProviderProps {
  children?: React.ReactNode;
  defaultLayout: React.ElementType;
}

/**
 * LayoutProvider component.
 *
 * @component
 */
const LayoutProvider: React.FC<LayoutProviderProps> = ({ children = null, defaultLayout }) => {
  return <Context.Provider value={{ defaultLayout }}>{children}</Context.Provider>;
};

LayoutProvider.displayName = 'LayoutProvider';

export default LayoutProvider;

// ********* Hooks

/**
 * Return LayoutProvider context.
 */
export const useLayoutProviderContext = (): LayoutProviderContext => {
  const ctx = React.useContext(Context);

  invariant(ctx, "Dont call 'useLayoutProviderContext' outside of <LayoutProvider>");

  return ctx;
};

/**
 * Returns default layout component
 */
export const useDefaultLayout = (): LayoutProviderContext['defaultLayout'] => {
  const ctx = useLayoutProviderContext();
  return ctx.defaultLayout;
};
